<template>
  <el-card id="creatCompany">
    <header>
      <h1>公司管理</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业ID</span>
            <el-input v-model="id" placeholder="企业ID" size="small"></el-input>
          </div>
          <div class="left-wrap">
            <span>企业</span>
            <el-input
              v-model="realnameSearch"
              placeholder="请输入企业名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>账号</span>
            <el-input
              v-model="usernameSearch"
              placeholder="请输入账号"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客服</span>
            <el-input
              v-model="operators"
              placeholder="客服人员"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>业务员</span>
            <el-input
              v-model="salesman"
              placeholder="业务员"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>锁定状态</span>
            <el-select
              v-model="value"
              placeholder="请选择锁定状态"
              size="small"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>客户等级</span>
            <el-select
              v-model="grade"
              placeholder="请选择客户等级"
              size="small"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="left-wrap">
            <span>通道类型</span>
            <el-select v-model="type" placeholder="请选择通道类型" size="small">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            size="small"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="small"
            class="reset"
            >重置</el-button
          >

          <el-button
            type="success"
            icon="el-icon-mic"
            @click="add()"
            class="addSeats"
            size="small"
            >添加公司账号</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        height="600"
        @row-dblclick="dblclick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="企业ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="企业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="账号"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="id"
          label="ID"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="seatCount"
          label="坐席额度"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seatMoney"
          label="套餐余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rate"
          label="费率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="salesman"
          label="业务员"
          align="center"
          style="color: red"
        ></el-table-column>
        <el-table-column
          prop="operators"
          label="客服人员"
          align="center"
        ></el-table-column>
        <el-table-column prop="grade" label="客户等级" align="center">
          <template v-slot="scope">
            <div>
              {{
                scope.row.grade == 1
                  ? "A"
                  : scope.row.grade == 2
                  ? "B"
                  : scope.row.grade == 3
                  ? "C"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="通道类型"
          align="center"
          style="color: red"
        >
          <template #default="scope">
            <el-button
              type="text"
              disabled
              size="small"
              v-if="scope.row.type == '0'"
              icon="el-icon-loading"
            ></el-button>
            <span>商机云</span>

            <!-- <span v-if="scope.row.type == 1">移动4</span>
            <span v-if="scope.row.type == 2">电信</span>
            <span v-if="scope.row.type == 3">联通2</span>
            <span v-if="scope.row.type == 4">联通1</span>
            <span v-if="scope.row.type == 5">移动ax</span>
            <span v-if="scope.row.type == 6">联通3</span>
            <span v-if="scope.row.type == 7">联通4</span>
            <span v-if="scope.row.type == 8">H</span>
            <span v-if="scope.row.type == 9">联通5</span>
            <span v-if="scope.row.type == 10">移动3</span>
            <span v-if="scope.row.type == 11">联通6</span>
            <span v-if="scope.row.type == 12">联通7</span>
            <span v-if="scope.row.type == 13">移动5</span>
            <span v-if="scope.row.type == 14">S</span>
            <span v-if="scope.row.type == 15">D</span> -->
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          style="dispaly: flex"
          fixed="right"
          width="260"
        >
          <template #default="scope">
            <!-- <el-button type="primary" size="small" @click="addNoteS(scope)"
              >查询是否开通短信服务</el-button
            > -->
            <el-button
              type="danger"
              size="small"
              @click="lock(scope)"
              v-if="scope.row.status == '2'"
              >解锁</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="lock(scope)"
              v-if="scope.row.status == '1'"
              >锁定</el-button
            >
            <!-- <el-button type="danger" size="small" @click="refund(scope)"
              >发起退款</el-button
            > -->
            <!-- v-if="localParentId == 7" -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 添加短信服务弹出框 -->
    <el-dialog title="添加短信服务" :visible.sync="addNoteVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="费率">
          <el-input v-model="form.s_rate" placeholder="请输入费率"></el-input>
        </el-form-item>
        <el-form-item label="套餐费率">
          <el-input
            v-model="form.s_seatRate"
            placeholder="请输入套餐费率"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addNoteVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addNoteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑短信服务弹出框 -->
    <el-dialog
      title="编辑短信服务"
      :visible.sync="updateNoteVisible"
      width="30%"
    >
      <el-form ref="form" :model="formss" label-width="100px">
        <el-form-item label="费率">
          <el-input v-model="formss.rate" placeholder="请输入费率"></el-input>
        </el-form-item>
        <el-form-item label="套餐费率">
          <el-input
            v-model="formss.seatRate"
            placeholder="请输入套餐费率"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateNoteVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="updateNoteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加弹出框 -->
    <el-dialog
      title="添加公司账号"
      :visible.sync="addVisible"
      top="0%"
      width="30%"
    >
      <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入账号"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="appid" prop="appid">
          <el-input
            v-model="form.appid"
            placeholder="请输入appid"
            clearable
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            show-password="true"
            clearable
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名字" prop="realname">
          <el-input
            v-model="form.realname"
            placeholder="请输入公司名字"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="签约周期" prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="请输入签约周期"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="客服人员" prop="operators">
          <el-input
            v-model="form.operators"
            placeholder="请输入运营人员"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="salesman">
          <el-input
            v-model="form.salesman"
            placeholder="请输入业务员"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="订阅类型" prop="whole">
          <el-select
            v-model="form.whole"
            placeholder="请选择订阅类型"
            size="small"
          >
            <el-option
              v-for="item in wholeList"
              :key="item.id"
              :label="item.lable"
              size="small"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="拨打模式" prop="ip">
          <el-select
            v-model="form.ip"
            placeholder="请选择拨打模式"
            size="small"
          >
            <el-option
              v-for="item in ipList"
              :key="item.id"
              :label="item.lable"
              size="small"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="坐席额度" prop="seatCount">
          <el-input-number
            v-model="form.seatCount"
            :precision="1"
            :step="1"
            :max="99999999"
            size="small"
            style="margin-left: 15px"
          ></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="余额">
          <el-input-number
            v-model="form.money"
            :precision="1"
            :step="1"
            :max="99999999"
            size="small"
            style="margin-left:15px"
          ></el-input-number>
        </el-form-item> -->
        <el-form-item label="费率" prop="rate">
          <el-input-number
            v-model="form.rate"
            :precision="3"
            :step="0.1"
            :max="1000"
            size="small"
            style="margin-left: 15px"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="套餐月租" prop="seatRate">
          <el-input-number
            v-model="form.seatRate"
            :precision="3"
            :step="0.1"
            :max="1000"
            size="small"
            style="margin-left: 15px"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="套餐外费率" prop="seatRateW">
          <el-input-number
            v-model="form.seatRateW"
            :precision="3"
            :step="0.1"
            :max="1000"
            size="small"
            style="margin-left: 15px"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog :visible.sync="updateShow" class="dblclick">
      <el-tabs v-model="activeName" @tab-click="fingOneIp">
        <el-tab-pane label="编辑" name="updata">
          <el-form
            ref="form"
            :model="forms"
            :rules="formRules"
            label-width="100px"
          >
            <el-form-item label="账号">
              <el-input
                v-model="forms.username"
                placeholder="请输入账号"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="appid">
              <el-input
                v-model="forms.appid"
                placeholder="请输入appid"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model="forms.password"
                placeholder="请输入密码"
                show-password="true"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名字">
              <el-input
                v-model="forms.realname"
                placeholder="请输入公司名字"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="签约周期" prop="remark">
              <el-input
                v-model="forms.remark"
                placeholder="签约周期"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="客服人员" prop="operators">
              <el-input
                v-model="forms.operators"
                placeholder="客服人员"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务员" prop="salesman">
              <el-input
                v-model="forms.salesman"
                placeholder="业务员"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户等级">
              <el-select
                v-model="forms.grade"
                placeholder="请选择客户等级"
                size="small"
                class="reset-width"
              >
                <el-option
                  v-for="item in gradeList"
                  :key="item.id"
                  :label="item.lable"
                  size="small"
                  :value="item.id"
                  class="reset-width"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订阅类型">
              <el-select
                v-model="forms.whole"
                placeholder="请选择订阅类型"
                size="small"
                class="reset-width"
              >
                <el-option
                  v-for="item in wholeList"
                  :key="item.id"
                  :label="item.lable"
                  size="small"
                  :value="item.id"
                  class="reset-width"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="拨打模式">
              <el-select
                v-model="forms.ip"
                placeholder="请选择拨打模式"
                size="small"
                class="reset-width"
              >
                <el-option
                  v-for="item in ipList"
                  :key="item.id"
                  :label="item.lable"
                  size="small"
                  :value="item.id"
                  class="reset-width"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="坐席额度">
              <el-input-number
                v-model="forms.seatCount"
                :precision="1"
                :step="1"
                :max="99999999"
                size="small"
                style="margin-left: 15px"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="费率">
              <el-input-number
                v-model="forms.rate"
                :precision="3"
                :step="0.1"
                :max="1000"
                size="small"
                style="margin-left: 15px"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="套餐月租">
              <el-input-number
                v-model="forms.seatRate"
                :precision="3"
                :step="0.1"
                :max="1000"
                size="small"
                style="margin-left: 15px"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="套餐外费率">
              <el-input-number
                v-model="forms.seatRateW"
                :precision="3"
                :step="0.1"
                :max="1000"
                size="small"
                style="margin-left: 15px"
              ></el-input-number>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="updateSubmit" style="width: 100%"
            >确 定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="充值" name="cz">
          <el-form ref="form" :model="forms" label-width="100px">
            <el-form-item label="余额">
              <el-input-number
                v-model="forms.money"
                :precision="3"
                :step="1"
                :max="9999999"
                style="margin-left: 15px"
              ></el-input-number>
            </el-form-item>
            <!-- 备注 -->
            <el-form-item label="备注">
              <el-input v-model="remarks" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <el-button @click="mqyspyy" type="primary">充值记录</el-button>
          <el-button
            type="primary"
            @click="rechargeSubmit"
            style="width: 86%"
            disabled
            v-if="forms.money == null || forms.money == 0"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="rechargeSubmit"
            style="width: 86%"
            v-else
            >确 定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="套餐余额" name="tcye">
          <el-form
            ref="form"
            :model="forms"
            :rules="formRules"
            label-width="100px"
          >
            <el-form-item label="套餐余额">
              <el-input-number
                v-model="forms.seatMoney"
                :precision="2"
                size="small"
                style="margin-left: 15px"
              ></el-input-number>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="balanceSubmit" style="width: 30%"
            >确 定</el-button
          >
        </el-tab-pane>
     
        <el-tab-pane label="号码费" name="middleNum">
          <el-form ref="form" :model="forms_middleNum" label-width="100px">
            <!-- 备注 -->
            <el-form-item label="单价">
              <!--<el-input v-model="forms_middleNum.price" type="text"></el-input>-->
              <el-input-number
                v-model="forms_middleNum.price"
                :precision="0"
                :step="1"
                :max="50"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="中间号">
              <el-input
                v-model="forms_middleNum.middleNum"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="middleNumSubmit" style="width: 30%"
            >确 定</el-button
          >
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 备注弹出框 -->
    <el-dialog
      title="编辑备注"
      :visible.sync="causeVisble"
      width="40%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="custom_stopReason"
          style="margin-bottom: 25px"
        >
        </el-input>
      </div>
      <el-button
        type="primary"
        style="width: 100%"
        disabled
        v-if="custom_stopReason == null || custom_stopReason == ''"
        >确 定</el-button
      >
      <el-button type="primary" @click="causeSubmit" style="width: 100%" v-else
        >确 定</el-button
      >
    </el-dialog>

    <!-- 历史充值记录弹出框 -->
    <el-drawer
      title="历史充值记录"
      :visible.sync="drawer"
      :direction="direction"
    >
      <el-table
        :data="rechargeRecord"
        style="width: 100%; text-align: center"
        height="95%"
      >
        <el-table-column prop="money" label="充值后余额"> </el-table-column>
        <el-table-column prop="oldMoney" label="充值前余额"> </el-table-column>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="remarks" label="备注信息"> </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleRechargeSizeChange"
        @current-change="handleRechargeCurrentChange"
        :current-page="rechargeParameter.pageIndex"
        :page-size="rechargeParameter.pageSize"
        layout="total, prev, pager, next"
        :total="rechargeParameter.pageTotal"
      >
      </el-pagination>
    </el-drawer>

    <!-- 查询是否开通短信服务弹出框 -->
    <el-dialog
      title="查询是否开通短信服务"
      :visible.sync="addNoteSVisible"
      width="30%"
    >
      <span>查询是否开通短信服务</span>
      <template #footer>
        <span class="dialog-footer mqyzs">
          <el-button @click="addNoteSVisible = false">取 消</el-button>
          <el-button type="primary" @click="addNoteSearch">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 发起退款弹出框 -->
    <!-- <el-dialog title="发起退款" :visible.sync="refundVisible">
      <el-form ref="addForm" :model="addForm" label-width="100px">
        <div class="refund">
          <el-form-item label="公司名称" prop="workMessage">
            <el-input
              v-model="addForm.workMessage"
              placeholder="请输入公司名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="行业类型" prop="askType">
            <el-input
              v-model="addForm.askType"
              placeholder="请输入行业类型"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户时间" prop="beginTime">
            <el-date-picker
              v-model="addForm.beginTime"
              type="datetime"
              disabled
              placeholder="选择开户时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="套餐" prop="endTime">
            <el-input
              v-model="addForm.endTime"
              placeholder="套餐"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="费率" prop="remark">
            <el-input
              v-model="addForm.remark"
              placeholder="费率"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="是否开票" prop="remark1">
            <el-select
              v-model="addForm.remark1"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in TFaskTypeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坐席额度" prop="askTime">
            <el-input
              v-model="addForm.askTime"
              placeholder="坐席额度"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <div class="balance">
          <el-form-item label="余额" prop="remark4">
            <el-input
              v-model="addForm.remark4"
              placeholder="余额"
              disabled
            ></el-input>
          </el-form-item>
          <el-button
            @click="mqyspyy"
            type="primary"
            style="margin-bottom: 22px; margin-left: 10px"
            >充值记录</el-button
          >
        </div>

        <el-form-item label="退款原因" prop="remark3">
          <el-input
            type="textarea"
            rows="2"
            v-model="addForm.remark3"
            placeholder="请输入退款原因"
          ></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer mqyzs">
          <el-button @click="refundVisible = false">取 消</el-button>
          <el-button type="primary" @click="refundSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog> -->
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  userFindlist,
  userUpdate,
  userAdd,
  userFindAppidbytype,
} from "@/api/user";
import { userSmsUpdate, userSmsAdd, userSmsFindOne } from "@/api/user-sms";
import { logUserList } from "@/api/log";
import { approverAdd } from "@/api/approver";
import { add_update, findOne } from "@/api/user-ip-limit";
import {
  add_update_middleNum,
  findOne_middleNum,
} from "@/api/user-middle-num.js";
export default {
  data() {
    return {
      forms_middleNum: [],
      forms_ip: [],
      localParentId: null,
      imgUrl: "",
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      addForm: {
        workImg: [],
      },
      TFaskTypeList: [
        {
          id: 0,
          title: "开发票",
        },
        {
          id: 1,
          title: "不开发票",
        },
      ],
      refundVisible: false,
      custom_stopReason: null,
      activeName: "updata",
      updateNoteVisible: false,
      addNoteVisible: false,
      addNoteSVisible: false,
      rechargeRecord: [],
      rechargeParameter: {
        pageTotal: 0, //页数
        pageIndex: 1, //当前在第几页
        pageSize: 10, //前端规定每页显示的数量
      },
      // 备注弹出框
      causeVisble: false,
      // direction: "ttb",
      // 历史充值记录弹出框
      drawer: false,
      direction: "rtl",
      //验证规则
      formRules: {
        remark: [
          { required: true, message: "请输入签约周期", trigger: "blur" },
        ],
        operators: [
          { required: true, message: "请输入客服人员", trigger: "blur" },
        ],
        salesman: [
          { required: true, message: "请输入业务员", trigger: "blur" },
        ],
      },
      id: null,
      realnameSearch: null,
      usernameSearch: null,
      balanceShow: false,
      operators: null,
      salesman: null,
      addVisible: false,
      updateShow: false,
      rechargeShow: false,
      remarks: null,
      value: null,
      grade: null,
      type: null,
      formss: {},
      gradeList: [
        {
          id: 1,
          lable: "A",
        },
        {
          id: 2,
          lable: "B",
        },
        {
          id: 3,
          lable: "C",
        },
      ],
      form: {
        s_rate: "",
        s_seatRate: "",
        rate: "",
        seatRate: "",
        seatMoney: "",
        username: "",
        password: "",
        appid: "",
        realname: "",
        money: 0,
        ip: "",
        remark: "",
        seatCount: "",
        oldMoney: "",
        operators: "",
        salesman: "",
        whole: "",
        rate: "",
        seatRate: "",
        seatRateW: "",
        stopReason: "",
        id: null,
      },
      forms: {
        rate: "",
        seatRate: "",
        seatMoney: "",
        username: "",
        password: "",
        appid: "",
        realname: "",
        money: 0,
        ip: "",
        remark: "",
        seatCount: "",
        oldMoney: "",
        operators: "",
        salesman: "",
        whole: "",
        rate: "",
        seatRate: "",
        seatRateW: "",
        stopReason: "",
        id: null,
      },
      wholeList: [
        {
          id: 0,
          lable: "共享计费",
        },
        {
          id: 1,
          lable: "完整订阅",
        },
        {
          id: 2,
          lable: "个人计费",
        },
      ],
      ipList: [
        {
          id: 1,
          lable: "回拨模式",
        },
      ],
      seatMoney: null,
      password: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      realname: null,
      channelType: "", //通道类型
      mid: "",
      options: [
        {
          value: "1",
          label: "未锁定",
        },
        {
          value: "2",
          label: "已锁定",
        },
      ],
      options1: [
        {
          value: "1",
          label: "A",
        },
        {
          value: "2",
          label: "B",
        },
        {
          value: "3",
          label: "C",
        },
      ],
      options2: [
        {
          value: "1",
          label: "移动4",
        },
        {
          value: "2",
          label: "电信",
        },
        {
          value: "3",
          label: "联通2",
        },
        {
          value: "4",
          label: "联通1",
        },
        {
          value: "5",
          label: "移动ax",
        },
        {
          value: "6",
          label: "联通3",
        },
        {
          value: "7",
          label: "联通4",
        },
        {
          value: "8",
          label: "H",
        },
        {
          value: "9",
          label: "联通5",
        },
        {
          value: "10",
          label: "移动3",
        },
        {
          value: "11",
          label: "联通6",
        },
        {
          value: "12",
          label: "联通7",
        },
        {
          value: "13",
          label: "移动5",
        },
        {
          value: "14",
          label: "S",
        },
        {
          value: "15",
          label: "D",
        },
      ],
      value: "",
      causeData: "", //备注内容显示
    };
  },
  created() {
    this.localParentId = localStorage.getItem("parentId");
    this.realname = localStorage.getItem("realname");
    this.getList();
  },
  methods: {
    //修改中间号扣费
    middleNumSubmit() {
      console.log("forms_ip_id", this.forms_middleNum.id);
      let params = {
        id: this.forms_middleNum.id,
        parentId: this.forms.id,
        price: this.forms_middleNum.price,
        middleNum: this.forms_middleNum.middleNum,
      };
      let res = add_update_middleNum(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
        this.activeName = "updata";
      });
    },
    //修改ip限制
    ipSubmit() {
      console.log("forms_ip_id", this.forms_ip.id);
      let params = {
        id: this.forms_ip.id,
        parentId: this.forms.id,
        provinceLimit: this.forms_ip.provinceLimit,
        cityLimit: this.forms_ip.cityLimit,
      };
      let res = add_update(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
        this.activeName = "updata";
      });
    },
    //查询单个公司ip限制
    fingOneIp(e) {
      // if (3 == e.index) {
      //   this.forms_ip = [];
      //   console.log("ip限制");

      //   let params = {
      //     parentId: this.forms.id,
      //   };
      //   let res = findOne(params);
      //   res.then((res) => {
      //     if (res.data.statusCode == "00000") {
      //       Message.success(res.data.message);
      //       this.forms_ip = res.data.data;
      //     } else {
      //       Message.error(res.data.message);
      //       this.forms_ip = [];
      //     }
      //   });
      // } else
      //  if (4 == e.index) {
      //   this.forms_middleNum = [];
      //   console.log("中间号");

      //   let params = {
      //     parentId: this.forms.id,
      //   };
      //   let res = findOne_middleNum(params);
      //   res.then((res) => {
      //     if (res.data.statusCode == "00000") {
      //       Message.success(res.data.message);
      //       this.forms_middleNum = res.data.data;
      //     } else {
      //       Message.error(res.data.message);
      //       this.forms_middleNum = [];
      //     }
      //   });
      // }
    },

    // 图片上传成功时
    handleAvatarSuccess(res, file) {
      this.imgUrl = res;
      let imageUrl = URL.createObjectURL(file.raw);
      // console.log("res", res);
      // console.log("imageUrl", imageUrl);
    },
    // 退费按钮
    refund(row) {
      console.log(1234, row.row);
      this.addForm.remark4 = row.row.money;
      this.addForm.remark = row.row.rate;
      this.addForm.askTime = row.row.seatCount;
      this.addForm.endTime = row.row.seatMoney;
      this.addForm.workMessage = row.row.realname;
      this.returnPremiumId = row.row.id;
      this.refundVisible = true;
      this.addForm.beginTime = row.row.createTime;
    },
    // 退费确认
    refundSubmit() {
      let params = {
        workOrderType: 5,
        returnPremiumId: this.returnPremiumId,
        workMessage: this.addForm.workMessage, // 公司名称
        askType: this.addForm.askType, // 行业类型
        endTime: this.addForm.endTime, // 套餐
        remark: this.addForm.remark, // 费率
        remark1: this.addForm.remark1, // 是否开票
        askTime: this.addForm.askTime, // 坐席额度
        remark3: this.addForm.remark3, // 退款原因
        remark4: this.addForm.remark4, // 余额
        beginTime: this.addForm.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.addForm.beginTime)
          : null, // 开户时间
      };
      console.log(2345567, params);
      // let res = approverAdd(params);
      // res.then((res) => {
      //   let code = res.data.statusCode;
      //   if (code == "00000") {
      //     Message.success(res.data);
      //   } else {
      //     Message.error("该功能暂未开放");
      //   }
      // });
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 备注关闭回调
    handleClose(done) {
      this.$confirm("请填写好备注信息点击确认进行关闭哦")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    // 双击事件
    dblclick(row) {
      this.updateShow = true;
      this.forms = { ...row };
      this.forms.money = null;
      this.forms.password = null;
      this.forms_ip = [];
      this.activeName = "updata";
    },
    // 套餐余额确认
    balanceSubmit() {
      let params = {
        seatMoney: this.forms.seatMoney,
        seatMoney2: this.forms.seatMoney2,
        id: this.forms.id,
      };
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
      });
      this.balanceShow = false;
    },
    // 套餐余额
    balance(row) {
      this.balanceShow = true;
      this.forms.seatMoney = row.row.seatMoney;
      this.forms.seatMoney2 = row.row.seatMoney;
      this.forms.id = row.row.id;
    },
    // 编辑短信服务
    updateNoteSubmit(row) {
      let params = {
        id: this.mid,
        rate: this.formss.rate,
        seatRate: this.formss.seatRate,
      };
      let res = userSmsUpdate(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.updateNoteVisible = false;
      });
    },
    // 确认添加短信服务
    addNoteSubmit() {
      let params = {
        parentId: this.form.id,
        rate: this.form.s_rate,
        seatRate: this.form.s_seatRate,
      };
      let res = userSmsAdd(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.addNoteVisible = false;
      });
    },
    // 查询是否开通短信服务
    addNoteS(row) {
      this.form = { ...row.row };
      this.addNoteSVisible = true;
    },
    // 确定查询是否开通短信服务
    addNoteSearch() {
      let params = {
        parentId: this.form.id,
      };
      let res = userSmsFindOne(params);
      res.then((res) => {
        Message.success(res.data.message);
        if (res.data.data != null) {
          // 编辑
          this.mid = res.data.data.id;
          this.formss = res.data.data;
          this.updateNoteVisible = true;
        } else {
          // 添加
          this.addNoteVisible = true;
        }
        this.addNoteSVisible = false;
      });
    },

    //确认上传备注
    causeSubmit() {
      let params = {
        id: this.companyRemarksId,
        stopReason: this.custom_stopReason,
      };
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success("修改成功");
          this.causeVisble = false;
          this.getList();
        }
      });
    },
    // 历史充值记录弹出框事件
    mqyspyy() {
      let params = {
        page: this.rechargeParameter.pageIndex,
        remarks: "充值金额",
        id: this.forms.id,
      };
      let res = logUserList(params);
      res.then((res) => {
        let data = res.data.data;
        this.rechargeRecord = data;
        this.rechargeParameter.pageTotal = res.data.total;
      });
      this.drawer = true;
    },
    handleRechargeSizeChange(val) {
      this.mqyspyy();
      console.log(`每页 ${val} 条`);
    },
    handleRechargeCurrentChange(val) {
      this.rechargeParameter.pageIndex = val;
      this.mqyspyy();
    },
    //重置按钮
    reset() {
      this.usernameSearch = null;
      this.realnameSearch = null;
      this.operators = null;
      this.grade = null;
      this.type = null;
      this.salesman = null;
      this.value = null;
      (this.forms_ip = []), (this.id = null), this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        id: this.id,
        page: this.page,
        size: this.size,
        username: this.usernameSearch,
        realname: this.realnameSearch,
        operators: this.operators,
        salesman: this.salesman,
        grade: this.grade,
        chooseType: this.type,
        parentId: 0,
        status: this.value ? this.value : null,
        stopReason: this.stopReason,
      };
      // ip传
      let axbArr = [3, 4, 6, 7, 9, 11, 12];
      let hbArr = [15, 14, 8, 2, 5, 13, 1, 10];
      if (axbArr.includes(this.type * 1)) {
        params.ip = 0;
      } else if (hbArr.includes(this.type * 1)) {
        params.ip = 1;
      } else {
        console.log("type在axbArr和hbArr中都没有");
      }
      let res = userFindlist(params);
      res.then((res) => {
        if (res.data.statusCode == null) {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;

          //循环每一个数据

          // for (var i = 0; i < this.tableData.length; i++) {
          //   let params = {
          //     ip: this.tableData[i].ip,
          //     appid: this.tableData[i].appid,
          //   };
          //   let index = i;
          //   let res = userFindAppidbytype(params);
          //   console.log("res666",res.data);
          //   res.then((resp) => {
          //     this.tableData[index].type = resp.data.data;
          //   });
          // }
        } else {
          Message.error(res.data.message);
        }
      });
    },
    //解锁/锁定
    lock(row) {
      this.companyRemarksId = row.row.id;
      this.causeData = row.row.stopReason;
      this.custom_stopReason = null;
      this.$confirm("是否确认该操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params;
          if (row.row.status == 2) {
            params = {
              id: row.row.id,
              status: 1,
            };
          } else {
            params = {
              id: row.row.id,
              status: 2,
            };
          }
          let res = userUpdate(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.$nextTick(() => {
                this.causeVisble = true;
              });
            } else {
              Message.error(res.data.message);
              this.getList();
            }
          });
        })
        .catch(() => {
          Message.info("已取消操作");
        });
    },
    //点击添加按钮
    add() {
      (this.form.username = ""),
        (this.form.password = ""),
        (this.form.realname = ""),
        (this.form.ip = ""),
        (this.form.rate = ""),
        (this.form.operators = ""),
        (this.form.salesman = ""),
        (this.form.seatRate = ""),
        (this.form.seatRateW = ""),
        (this.form.appid = ""),
        (this.form.remark = ""),
        (this.form.seatCount = ""),
        (this.form.whole = ""),
        (this.addVisible = true);
    },
    //添加确定按钮
    saveAdd() {
      if (
        this.form.salesman == "" ||
        this.form.operators == "" ||
        this.form.remark == ""
      ) {
        Message.error("请输入客服人员、业务员及签约周期！");
      } else {
        let params = {
          username: this.form.username,
          password: this.form.password,
          realname: this.form.realname,
          ip: this.form.ip,
          rate: this.form.rate,
          operators: this.form.operators,
          salesman: this.form.salesman,
          seatRate: this.form.seatRate,
          seatRateW: this.form.seatRateW,
          appid: this.form.appid,
          remark: this.form.remark,
          seatCount: this.form.seatCount,
          whole: this.form.whole,
        };
        let res = userAdd(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        });
        this.addVisible = false;
        this.getList();

      }
    },
    //编辑按钮
    update(row) {
      console.log(row.row);
      this.updateShow = true;
      this.forms.username = row.row.username;
      this.forms.appid = row.row.appid;
      // this.forms.password = row.row.password;
      this.forms.password = null;
      this.forms.realname = row.row.realname;
      this.forms.grade = row.row.grade;
      this.forms.remark = row.row.remark;
      this.forms.whole = row.row.whole;
      this.forms.seatCount = row.row.seatCount;
      this.forms.operators = row.row.operators;
      this.forms.salesman = row.row.salesman;
      this.forms.rate = row.row.rate;
      this.forms.seatRate = row.row.seatRate;
      this.forms.seatRateW = row.row.seatRateW;
      this.forms.id = row.row.id;
      this.forms.ip = row.row.ip;
      this.forms.seatMoney = row.row.seatMoney;
      // this.forms = row.row;
    },
    //编辑确认
    updateSubmit() {
      let params = {
        username: this.forms.username,
        appid: this.forms.appid,
        password: this.forms.password,
        realname: this.forms.realname,
        grade: this.forms.grade,
        remark: this.forms.remark, //签约周期
        whole: this.forms.whole,
        seatCount: this.forms.seatCount,
        operators: this.forms.operators,
        salesman: this.forms.salesman,
        rate: this.forms.rate,
        seatRate: this.forms.seatRate,
        seatRateW: this.forms.seatRateW,
        id: this.forms.id,
        ip: this.forms.ip,
      };
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
      });
    },
    //充值按钮
    recharge(row) {
      this.rechargeShow = true;
      this.forms.money = null;
      this.remarks = null;
      this.forms.id = row.row.id;
      this.forms.realname = row.row.realname;
      this.forms.oldMoney = row.row.money;
    },
    //充值确定按钮
    rechargeSubmit() {
      let params = {
        id: this.forms.id,
        realname: this.forms.realname,
        money: this.forms.money,
        oldMoney: this.forms.oldMoney,
        remarks: this.remarks,
      };
      let res = userUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.updateShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.updateShow = false;
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#creatCompany {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 21%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .recharge-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dblclick /deep/.el-dialog__header {
    padding: 0;
  }
  .refund {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
  .balance {
    display: flex;
    align-items: center;
  }
}
</style>