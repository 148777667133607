/**
 * company_renew
 * 
 */

import request from '@/utils/request'

// 
export const add_update_middleNum = (data) => {
    return request({
        method: 'post',
        url: '/sysUserMiddle/add-update',
        data
    })
}
// 
export const findOne_middleNum = (data) => {
    return request({
        method: 'post',
        url: '/sysUserMiddle/findOne',
        data
    })
}

